<template>
  <div>
    <div class="d-inline-flex justify-content-between form-inline-input">
      <FormGroup
        for="countryCode"
        label="Country Code"
        class="w-25-35"
      >
        <b-form-select
          v-model="countryCode"
          :options="countryCodeOptions"
        />
      </FormGroup>
      <FormGroup
        :state="status"
        :invalid-feedback="invalidFeedback"
        valid-feedback="Guest Signup Success!"
        for="phoneNumber"
        label="Phone Number"
        class="w-75-65"
      >
        <b-input
          v-model="phoneNumber"
          :state="status"
          :disabled="phoneDisabled"
          :class="[{'is-invalid': status === 'invalid'}]"
          type="tel"
          placeholder="Mobile Number"
          @keyup.enter.native="submit"
        />
      </FormGroup>
    </div>
    <b-button
      id="phone-verify-button"
      :disabled="phoneDisabled"
      :variant="buttonStatus"
      class="form-inline-button"
      @click="submit"
    >
      <span v-if="status === 'verifying'">
        verifying
        <b-spinner small />
      </span>
      <span v-else-if="status === 'submitting'">
        submitting
        <b-spinner small />
      </span>
      <span v-else>{{ buttonText }}</span>
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FormGroup from '../Form/FormGroup.vue';

export default {
  name: 'InternalGuestSignupPhone',
  components: {
    FormGroup,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      countryCode: '+1',
      phoneNumber: this.value,
      status: null,
      phoneDisabled: false,
      invalidFeedback: '',
    };
  },
  computed: {
    ...mapGetters([
      'countryCodeOptions',
    ]),
    buttonText() {
      switch (this.status) {
        case 'valid':
          return '🥰';
        case 'invalid':
          return 'Try again';
        default:
          return 'Yeehaw';
      }
    },
    buttonStatus() {
      switch (this.status) {
        case 'valid':
          return 'success';
        case 'invalid':
          return 'danger';
        default:
          return 'primary';
      }
    },
  },
  methods: {
    async submit() {
      if (!this.phoneNumber) {
        this.status = 'invalid';
        this.invalidFeedback = 'Phone Number required.';
        return;
      }
      this.phoneDisabled = true;
      this.invalidFeedback = '';
      this.status = 'verifying';
      try {
        // phone number verification
        const verification = await this.$store.dispatch('verifyPhoneData', {
          countryCode: this.countryCode,
          phone: this.countryCode + this.phoneNumber,
        });

        switch (verification) {
          case 'new':
            this.status = 'submitting';
            break;
          case 'user':
            this.status = 'invalid';
            this.invalidFeedback = 'User already exists';
            this.phoneDisabled = false;
            return;
          default:
            this.status = 'invalid';
            this.invalidFeedback = 'Phone number is not valid.';
            this.phoneDisabled = false;
            return;
        }

        // is new user; do guest signup
        await this.$store.dispatch('doGuestSignup', (this.countryCode + this.phoneNumber));

        this.status = 'valid';
        this.phoneDisabled = true;
        this.$session.set('sales-attribution', this.salesAttribution);
      } catch (error) {
        this.status = 'invalid';
        this.invalidFeedback = `[${error.errorCode}] ${error.errorMessage}.`;
        this.phoneDisabled = false;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
#phone-verify-button {
  margin-top: 21px;
}
</style>
