<template>
  <div
    v-if="hasPageAccess(pageName)"
    id="internal-guest-signup"
  >
    <h2 aria-label="Internal Guest Signup Up">
      Internal Guest Signup
    </h2>

    <b-form
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <!-- ===== SALES ATTRIBUTION ===== -->
        <FormSection title="Sales Attribution">
          <FormGroup
            for="salesAttribution"
            label="Initials of first and last name"
            class="w-100"
          >
            <FormInput
              bind-id="salesAttribution"
              get="salesAttribution"
              set="UPDATE_SALES_ATTRIBUTION"
            />
          </FormGroup>
        </FormSection>
        <!-- <FormSection title="Import via CVS">
          <FormGroup
            for="hasCountryCode"
            label="Has Country Code?"
            class="w-100">
            <b-checkbox v-model="hasCountryCode"/>
          </FormGroup>
          <FormGroup
            for="countryCode"
            label="Country Code"
            class="w-100">
            <b-form-select
              :options="countryCodeOptions"
              v-model="countryCode"/>
          </FormGroup>
          <FormGroup
            for="countryCode"
            label="Country Code"
            class="w-100">
            <b-input
              v-model="cvs"
              class="cvs"/>
          </FormGroup>
        </FormSection> -->
        <FormSection title="Phone Number">
          <FormGroup
            for="phoneNumber"
            class="w-100"
          >
            <!-- for loop? -->
            <div
              v-for="index in cvs"
              :key="index"
            >
              <InternalGuestSignupPhone :value="index" />
            </div>
            <div
              v-for="index in count"
              :key="index"
            >
              <InternalGuestSignupPhone />
            </div>
          </FormGroup>
          <b-btn
            class="plus-button"
            size="lg"
            variant="outline-primary"
            block
            @click="count++"
          >
            +
          </b-btn>
        </FormSection>
      </div>
    </b-form>
    <div class="tools">
      count: {{ count }}
      <br>
      <b-input
        v-model="add"
        placeholder="add moar"
        type="number"
        class="add-input"
        @keyup.enter.native="count = Number(count) + Number(add)"
      />
      <b-btn
        class="add-button"
        size="lg"
        variant="primary"
        @click="count = Number(count) + Number(add)"
      >
        +
      </b-btn>
    </div>
  </div>
  <div v-else>
    <Error403 />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as role from '../../utils/permissions';

import Error403 from '../Error/403.vue';

import FormErrors from '../Form/FormErrors.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormInput from '../Form/FormInput.vue';
import InternalGuestSignupPhone from './InternalGuestSignupPhone.vue';

export default {
  name: 'InternalGuestSignup',
  components: {
    Error403,
    FormErrors,
    FormProgressBar,
    FormSection,
    FormGroup,
    FormInput,
    InternalGuestSignupPhone,
  },
  data() {
    return {
      pageName: 'internalGuestSignup',
      hasCountryCode: true,
      countryCode: '+1',
      cvs: [],
      count: 1,
      add: null,
    };
  },
  computed: {
    ...mapGetters([
      'salesAttribution',
      'countryCodeOptions',
    ]),
    sumCount() {
      return this.count + this.cvs.length;
    },
  },
  methods: {
    hasPageAccess: role.hasPageAccess,
    hasComponentAccess: role.hasComponentAccess,
    progress() { return { full: true }; },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

#internal-guest-signup {
  .form {
    padding: 40px 20px 0px 20px;
  }
  .plus-button {
    height: 50px;
  }
  .tools {
    position: fixed;
    width: 100%;
    bottom: 10px;
    text-align: center;
    .add-button {
      width: 50px;
      height: 50px;
      display: inline-block;
    }
    .add-input {
      display: inline-block;
      width: 100px;
      height: 50px;
      text-align: center;
      margin: 5px;
    }
  }
}

</style>
